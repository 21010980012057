import nxModule from 'nxModule';
import _ from 'lodash';
import moment from "moment";
import BigNumber from 'bignumber.js';
import {FEE_AMORTIZATION_RELATION} from '../common/edit-loan-amortization.component';
import {Subscription} from 'rxjs/Subscription';
import {sum} from 'shared/common/MathUtils';
import {RECREATE_DISCOUNT_CHARGES_LEDGER_MESSAGE} from 'components/support/misc/update-loan-maturity-date/update-loan-maturity-date.component';

import templateUrl from './customer-loans-edit-amortization.template.html';

nxModule.component('customerLoansEditAmortization', {
  templateUrl: templateUrl,
  bindings: {
    editPrincipal: '<'
  },
  controller: function ($route, $location, $filter, dict, customerCache,
                        customerService, fileService, loanService, confirmationTemplate, command) {
    let that = this;

    let loanId = Number($route.current.params['loanId']);
    let customerId = Number($route.current.params['customerId']);

    const recreateDiscountChargesLedgerMessage = RECREATE_DISCOUNT_CHARGES_LEDGER_MESSAGE

    const subscription = new Subscription();

    subscription.add(customerCache.loans(customerId).toObservable()
      .subscribe(loans => {
        if (loanId) {
          that.loan = _.find(loans, el => el.id === loanId);
          that.clonedLoan = angular.copy(that.loan);
        }
      }));

    subscription.add(customerCache.profile(customerId).toObservable()
      .subscribe(profile => that.profile = profile)
    );


    that.redirectBack = () => {
      $location.path(`/customer/${customerId}/loans/${loanId}`)
    };

    that.save = async () => {
      if (that.editPrincipal) {
        editLoanAmortizationWithPrincipal();
      } else {
        editLoanAmortizationWithoutPrincipal();
      }
    };

    const addInterests = (loanRequest, updateDetails) => {
      updateDetails.push({
        label: 'Existing interest amount',
        description: $filter('nxCurrency')(that.clonedLoan.interestAmount)
      });
      updateDetails.push({
        label: 'Updated interest amount',
        description: $filter('nxCurrency')(loanRequest.interestAmount)
      });
      updateDetails.push({
        label: 'Existing interest balance',
        description: $filter('nxCurrency')(that.clonedLoan.interestBalance)
      });
      updateDetails.push({
        label: 'Updated interest balance',
        description: $filter('nxCurrency')(loanRequest.interestBalance)
      });
    }

    const addPrincipal = (loanRequest, updateDetails) => {
      updateDetails.push({
        label: 'Existing principal amount',
        description: $filter('nxCurrency')(that.clonedLoan.principalAmount)
      });
      updateDetails.push({
        label: 'Updated principal amount',
        description: $filter('nxCurrency')(loanRequest.principalAmount)
      });
      updateDetails.push({
        label: 'Existing principal balance',
        description: $filter('nxCurrency')(that.clonedLoan.principalBalance)
      });
      updateDetails.push({
        label: 'Updated principal balance',
        description: $filter('nxCurrency')(loanRequest.principalBalance)
      });
    }

    const hasMaturityDateChanged = (newMaturityDate) => {
      return !moment(that.clonedLoan.maturityDate).isSame(moment(newMaturityDate));
    }

    const addMaturityDateChange = (newMaturityDate, updateDetails) => {
      if (hasMaturityDateChanged(newMaturityDate)) {
        updateDetails.push({
          label: 'Existing maturity date',
          description: $filter('nxDate')(that.clonedLoan.maturityDate)
        });
        updateDetails.push({
          label: 'Updated maturity date',
          description: $filter('nxDate')(newMaturityDate)
        });
      }
    }

    const editLoanAmortizationWithPrincipal = async () => {
      const loanRequest = _.cloneDeep(that.clonedLoan);
      updatePrincipalInput(loanRequest);
      updateInterestInput(loanRequest);

      const updateDetails = [];
      addInterests(loanRequest, updateDetails);
      addPrincipal(loanRequest, updateDetails);

      const newMaturityDate = that.clonedLoan.amortizationSchedule.list[loanRequest.totalAmortizationNumber - 1].dueDate;
      addMaturityDateChange(newMaturityDate, updateDetails);

      const proceed = await confirmationTemplate({
        question: `Interests and principal balance of the loan will be updated. Proceed?`,
        warning: 'This operation cannot be reverted and the GL Balances will not be updated automatically. Please make sure that the data is correct.'
          + (hasMaturityDateChanged(newMaturityDate) && that.clonedLoan.discountChargesLedger ? `<br/><br/>${recreateDiscountChargesLedgerMessage}` : ''),
        details: updateDetails
      });

      if (!proceed) {
        return;
      }

      await command.execute('EditLoanAmortizationWithPrincipal', {
        productId: loanRequest.id,
        principalBalance: loanRequest.principalBalance,
        interestAmount: loanRequest.interestAmount,
        interestBalance: loanRequest.interestBalance,
        amortizationSchedule: loanRequest.amortizationSchedule.list,
        amortizationSpecificFees: loanRequest.customFees.filter(f => f.type === FEE_AMORTIZATION_RELATION.AMORTIZATION_SPECIFIC),
        amortizedFees: loanRequest.customFees.filter(f => f.type === FEE_AMORTIZATION_RELATION.AMORTIZED)
      }).toPromise();

      await customerCache.loans(customerId).refetch();
      await customerCache.customerProductFees(customerId).refetch();
      await customerCache.loanPDCs(customerId, loanRequest.id).refetch();
      that.redirectBack();
    };

    const editLoanAmortizationWithoutPrincipal = async () => {
      const loanRequest = _.cloneDeep(that.clonedLoan);
      updateInterestInput(loanRequest);

      const updateDetails = [];
      addInterests(loanRequest, updateDetails);

      const confirmed = await confirmationTemplate({
        question: 'Do you want to update the interest of amortization schedule?',
        warning: 'This operation cannot be reverted safely, please make sure that the data is correct.',
        details: updateDetails
      });

      if (!confirmed) {
        return;
      }

      await command.execute('EditLoanAmortizationWithoutPrincipal', {
        productId: loanRequest.id,
        interestAmount: loanRequest.interestAmount,
        interestBalance: loanRequest.interestBalance,
        amortizationSchedule: loanRequest.amortizationSchedule.list,
        amortizationSpecificFees: loanRequest.customFees.filter(f => f.type === FEE_AMORTIZATION_RELATION.AMORTIZATION_SPECIFIC),
        amortizedFees: loanRequest.customFees.filter(f => f.type === FEE_AMORTIZATION_RELATION.AMORTIZED)
      }).toPromise();

      await customerCache.loans(customerId).refetch();
      await customerCache.customerProductFees(customerId).refetch();
      that.redirectBack();
    };

    function updateInterestInput(loan) {
      let interestAmount = new BigNumber(0);
      let interestBalance = new BigNumber(0);

      for (const a of loan.amortizationSchedule.list) {
        interestAmount = interestAmount.plus(a.interestAmount);
        interestBalance = interestBalance.plus(a.interestBalance);
      }

      loan.interestAmount = interestAmount.toNumber();
      loan.interestBalance = interestBalance.toNumber();
    }

    const updatePrincipalInput = (loan) => {
      loan.principalBalance = sum(loan.amortizationSchedule.list.map(amortization => amortization.principalBalance));
      loan.principalAmount = sum(loan.amortizationSchedule.list.map(amortization => amortization.principalAmount));
    };

    that.headerLabel = () => {
      return `Edit loan amortization ${that.editPrincipal ? '(inc. principal)' : ''}`;
    }

    that.$onDestroy = () => {
      subscription.unsubscribe();
    }
  }
});
