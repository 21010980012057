import {IFormController, ILocationService} from 'angular';
import {CustomerLoanCreateService} from 'components/customer/loans/new-loan/page/customer-loan-create.service';
import {CustomerLoanRenewalService} from 'components/customer/loans/new-loan/page/renewal/customer-loan-renewal.service';
import {CustomerCache} from 'components/service/customer.cache.types';
import FeeDefinitionCache from 'components/service/fee-definition.cache';
import {FeeDefinition} from 'components/service/fees/fee.types';
import {LoanType} from 'components/service/loan-type.types';
import {NxIFilterService} from 'components/technical/angular-filters';
import customFieldService from 'custom-field/CustomFieldService';
import nxModule from 'nxModule';
import ConfirmationTemplate from 'shared/common/confirmationTemplate';
import Authentication from 'shared/utils/authentication';
import {CommandService} from 'shared/utils/command/command.types';
import {HttpService} from 'shared/utils/httpService';
import {RecursivePartial} from '../../../../../../shared/utils/RecursivePartial';
import {CreateLoanInput} from '../../../../../service/create-loan-input.types';
import {Loan} from '../../../../../service/loan.types';
import './customer-loan-renewal-page.style.less';
import templateUrl from './customer-loan-renewal-page.template.html';

class CustomerLoanRenewalPage {

  private loan!: Loan;

  public loanForm!: IFormController;
  private loanInput: RecursivePartial<CreateLoanInput> = {};
  private loanTypeId!: number;
  private penaltyDefinition!: FeeDefinition;

  constructor(private $location: ILocationService,
              private command: CommandService,
              private customerCache: CustomerCache,
              private confirmationTemplate: ConfirmationTemplate,
              private customerLoanCreateService: CustomerLoanCreateService,
              private customerLoanRenewalService: CustomerLoanRenewalService,
              private authentication: Authentication,
              private $filter: NxIFilterService,
              private feeDefinitionsCache: FeeDefinitionCache,
              private http: HttpService) {
  }

  async $onInit(): Promise<void> {
    this.loanInput = await this.customerLoanCreateService.createLoanInputFromExistingLoan(this.loan, 'RENEWAL');
    this.loanInput.renewedLoanId = this.loan.id;
    this.loanInput.customFieldValues = await customFieldService.readValuesMap({productId: this.loan.id});
    const [feeDefinitions, loanType] = await Promise.all([
      this.feeDefinitionsCache.toPromise(),
      this.http.get<LoanType>(`/products/loans/types/${this.loanInput.loanTypeId}`).toPromise()
    ]);
    const penalty = feeDefinitions.find(f => f.feeClass === 'PENALTY' && loanType.productDefinition.id === f.productDefinitionId);
    if(penalty) {
      this.penaltyDefinition = penalty;
    }
  }

  redirectBack(): void {
    this.$location.path(`/customer/${this.loan.customerId}/loans/${this.loan.id}`);
  }

  async renew(): Promise<void> {
    if(this.loanInput.principalAmount === undefined) {
      throw new Error('Missing loan principal');
    }

    let question = 'The loan will be renewed, do you wish to proceed?';

    if (this.loanInput.forcedRenewal) {
      question = 'The paid principal did not meet the requirement, the loan will be force renewed, do you wish to proceed?';
    }

    const proceed = await this.confirmationTemplate({
      question: question,
      warning: `Old loan will be preterminated using the new loan's release amount.`,
      details: [
        {label: 'Renewed loan', description: this.loan.productNumber},
        {label: 'Principal amount', description: this.$filter('nxCurrency')(this.loanInput.principalAmount)}
      ]
    });

    if (!proceed) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const input = this.customerLoanCreateService.createLoanCommandInput(this.loanInput);
    if(this.penaltyDefinition.extraOptions?.LOAN_BOARD) {
      input.penalty = undefined;
    }
    const response = await this.command.execute('CreateRenewedLoan', input).toPromise();

    if (response && !response.approvalRequired) {
      const customerId = this.loan.customerId;
      this.customerCache.loans(customerId).refetch();
      this.customerCache.customerProductFees(customerId).refetch();
      this.authentication.permissions['CST_CREDIT_LINE_READ'] ?  this.customerCache.creditLines(customerId).refetch() : Promise.resolve();
      this.redirectBack();
    }
  }

}

nxModule.component('customerLoanRenewalPage', {
  templateUrl,
  bindings: {
    loan: '<'
  },
  controller: CustomerLoanRenewalPage
});
