import {ProductAndFeeDefintion} from 'components/service/fees/fee.types';
import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './customer-loans-edit-params-and-charges.template.html';

class CustomerLoanEditParamsAndCharges {

  constructor($location, command, notification, confirmation, customerCache, authentication, http, customerLoanCreateService) {
    this.$location = $location;
    this.command = command;
    this.notification = notification;
    this.confirmation = confirmation;
    this.customerCache = customerCache;
    this.authentication = authentication;
    this.customerLoanCreateService = customerLoanCreateService;
    this.http = http;
  }

  async $onInit()  {
    this.loan.loanTypeId = this.loan.typeId;
    this.loan.automaticTransferAgreement = this.loan.automaticTransferAgreement ? this.loan.automaticTransferAgreement : { transferStrategy: 'NONE'};

    const interestType =  this.loan.interestType || this.loan.loanType.interestType
    if (interestType === 'ADD_ON_RATE') {
      this.loan.interestRate = this.loan.monthlyInterestRate
    }

    this.onFeesAutoUpdated = message => {
      this.feesAutoUpdated = message;
    };

    const feeDefinitions = await this.http.get(`/products/product-fee-definitions/${this.loan.id}`).toPromise();
    const penalty = feeDefinitions.find(f => f.feeClass === 'PENALTY');
    this.penaltyDefinition = penalty;
  }

  async updateParamsAndCharges() {
    const proceed = await this.confirmation("Are you sure you want to update the loan parameters and charges?");
    if (!proceed) {
      this.notification("No changes were made to the loan parameters and charges.");
      return;
    }

    const request = this.customerLoanCreateService.createLoanCommandInput(this.loan);
    if(this.penaltyDefinition.extraOptions?.LOAN_BOARD) {
      request.penalty = undefined;
    }

    const response = await this.command.execute("EditLoanParametersAndCharges", request).toPromise();
    if (!response.approvalRequired) {
      this.customerCache.loans(this.customerId).refetch();
      this.customerCache.customerProductFees(this.customerId).refetch();
      this.authentication.permissions['CST_CREDIT_LINE_READ'] ?  this.customerCache.creditLines(this.customerId).refetch() : Promise.resolve();
      this.redirectBack();
    }
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.id}`);
  }

}

nxModule.component('customerLoanEditParamsAndCharges', {
  templateUrl,
  controller: CustomerLoanEditParamsAndCharges,
  bindings: {
    customerId: '<',
    loan: '<'
  }
});
