import BigNumber from 'bignumber.js';
import nxModule from 'nxModule';

import templateUrl from './edit-loan-amortization.template.html';
import systemPropertyService from '../../../../../react/system/systemPropertyService';

export const FEE_AMORTIZATION_RELATION = Object.freeze({
  AMORTIZATION_SPECIFIC: 'AMORTIZATION_SPECIFIC',
  AMORTIZED: 'AMORTIZED'
});

nxModule.component('customerEditLoanAmortization', {
  templateUrl: templateUrl,
  bindings: {
    formAmortization: '=',
    sumsValid: '=',
    loan: '=',
    editPrincipal: '='
  },
  controller: function ($scope, $element, customerCache, loanService, feeDefinitionsCache) {
    const that = this;

    //status options for amortization
    that.statusOptions = ['PAID', 'UNPAID', 'DUE', 'OVERDUE'];

    that.getCustomFees = () => {
      return that.loan ? that.loan.customFees : [];
    }

    that.$onInit = () => {
      $element[0].addEventListener('transitionstart', function(event) {
        if(event.propertyName === 'max-height') {
          const elem = angular.element(event.target);
          if(!elem.hasClass('toggleable-panel-wrapper')) {
            return;
          }

          if(elem.hasClass('allow-overflow')) {
            elem.removeClass('allow-overflow')
          } else {
            elem.addClass('allow-overflow');
          }
        }
      });

      that.allowUpdatePrincipalAmount = systemPropertyService.getProperty('MIGRATION_MODE_ENABLED') === 'TRUE';
    }

    $scope.$watch('$ctrl.loan', async () => {
      if (that.loan && that.loan.amortizationSchedule) {
        that.formAmortization.$setSubmitted();
        that.calculatePrincipalTotal();
        await that.assignCustomFees();
        await that.fetchLoanPaymentFeeDefinitions();
      }
    });

    that.hasCbu = () => {
      return that.loan.cbuSavingsAccountId || that.loan.cbuFundAccountId || that.loan.amortizationSchedule.list.some(item => item['cbuChargeAmount'] !== 0)
    }

    that.hasPf = () => {
      return that.loan.pfSavingsAccountId || that.loan.amortizationSchedule.list.some(item => item['pfChargeAmount'] !== 0);
    }

    that.hasTp = () => {
      return that.loan.tpSavingsAccountId || that.loan.amortizationSchedule.list.some(item => item['tpChargeAmount'] !== 0);
    }

    that.assignCustomFees = async () => {
      if (!that.loan) return;
      that.loan.customFees = await that.fetchCustomFees();
    };

    that.fetchLoanPaymentFeeDefinitions = async () => {
      const feeDefs = await feeDefinitionsCache.toPromise();
      that.loanPaymentFeeDefinitions = feeDefs.filter(feeDefinition => feeDefinition.productDefinitionId === that.loan.definitionId
        && feeDefinition.feeClass === 'CUSTOM'
        && feeDefinition.applyOn === 'LOAN_PAYMENT'
      );
    };

    that.fetchCustomFees = async () => {
      const [feeDefinitions, customerFees, amortizedFees] = await Promise.all([
        feeDefinitionsCache.toPromise(),
        customerCache.customerProductFees(that.loan.customerId).toPromise(),
        loanService.getAmortizedFees(that.loan.id)
      ]);

      const customFeeDefinitionIdMap = {};
      feeDefinitions
        .filter(f => f.feeClass === 'CUSTOM')
        .forEach(f => { customFeeDefinitionIdMap[f.id] = f });

      const customAmortizationSpecificFees = customerFees.filter(f => f.productId === that.loan.id)
        .filter(f => f.applyOn !== 'LOAN_AMORTIZATION') // these are called AmortizedFee and will be handled separately
        .filter(f => f.feeDefinitionId in customFeeDefinitionIdMap)
        .filter(f => f.applyPredicates?.some(fp => fp.type === 'LoanAmortizationLineNumberFeePredicate'))
        .map(f => ({
          ...f,
          amortizationLineNumber: f.applyPredicates?.find(fp => fp.type === 'LoanAmortizationLineNumberFeePredicate').value,
          type: FEE_AMORTIZATION_RELATION.AMORTIZATION_SPECIFIC,
          name: customFeeDefinitionIdMap[f.feeDefinitionId].feeName
        }));

      const customAmortizedFees = amortizedFees
        .filter(f => f.feeDefinitionId in customFeeDefinitionIdMap)
        .map(f => ({
          ...f,
          type: FEE_AMORTIZATION_RELATION.AMORTIZED,
          name: customFeeDefinitionIdMap[f.feeDefinitionId].feeName
        }));

      return [
        ...customAmortizationSpecificFees,
        ...customAmortizedFees
      ];
    };

    that.amortizationClicked = (amortization, $event) => {
      $event.stopPropagation();
      that.selectedAmortizationLineNumber = amortization.lineNumber;
    };

    that.hideCustomFeesDetails = () => {
      that.selectedAmortizationLineNumber = null;
    };

    that.calculateAmount = (item) => {
      item.amortizationAmount = bn(item.principalAmount)
        .plus(bn(item.interestAmount))
        .plus(bn(item.customFeesAmount))
        .plus(bn(item.penaltyAmount))
        .plus(bn(item.penaltyMaturityAmount))
        .plus(bn(item.pastDueInterestAmount))
        .plus(bn(item.pastDueMaturityInterestAmount))
        .plus(bn(item.cbuChargeAmount))
        .plus(bn(item.pfChargeAmount))
        .plus(bn(item.tpChargeAmount))
        .toNumber();
    };

    that.calculateBalance = (item) => {
      item.amortizationBalance = bn(item.principalBalance)
        .plus(bn(item.interestBalance))
        .plus(bn(item.customFeesBalance))
        .plus(bn(item.penaltyBalance))
        .plus(bn(item.penaltyMaturityBalance))
        .plus(bn(item.pastDueInterestBalance))
        .plus(bn(item.pastDueMaturityInterestBalance))
        .plus(bn(item.cbuChargeBalance))
        .plus(bn(item.pfChargeBalance))
        .plus(bn(item.tpChargeBalance))
        .toNumber();
    };

    that.calculatePrincipalTotal = () => {
      let balance = new BigNumber(0);
      let amount = new BigNumber(0);
      for (let a of that.loan.amortizationSchedule.list) {
        balance = balance.plus(a.principalBalance);
        amount = amount.plus(a.principalAmount);
      }

      that.totalPrincipalAmount = amount.toNumber();
      that.totalPrincipalBalance = balance.toNumber();
      this.sumsValid = that.allowUpdatePrincipalAmount || (that.loan.principalAmount === that.totalPrincipalAmount);
    };

    that.recalculateAmortizations = () => {
      that.loan.amortizationSchedule.list.forEach(a => {
        that.calculateBalance(a);
        that.calculateAmount(a);
      })
    }

    function bn(number) {
      return number ? new BigNumber(number) : new BigNumber(0)
    }
  }
});
